html, body, #root {
    padding: 0;
    margin: 0;
    font-family: Gotham, sans-serif;
}

* {
    box-sizing: border-box;
    margin: 0;
    line-height: 1;
}
